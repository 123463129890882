<template>
  <div>
    <div class="d-flex justify-content-between align-items-end mb-3">
      <!-- Search inputs container -->
      <div class="search-container d-flex gap-3">
        <div class="search-field text-left">
          <label class="small text-muted mb-1"><strong>Description/Properties</strong></label>
          <div class="search-input-wrapper">
            <input 
              type="text" 
              v-model="searchQuery" 
              @input="handleSearch"
              placeholder="Search description or properties..."
              class="form-control"
            />
            <icon
              v-if="searchQuery"
              :data="timesCircle"
              class="clear-icon"
              color="#dc3545"
              height="16"
              width="16"
              @click="clearSearch('description')"
            />
          </div>
        </div>
        <div class="search-field text-left">
          <label class="small text-muted mb-1"><strong>Subject ID</strong></label>
          <div class="search-input-wrapper">
            <input 
              type="text" 
              v-model="subjectIdSearch" 
              @input="handleSearch"
              placeholder="Search subject ID..."
              class="form-control"
            />
            <icon
              v-if="subjectIdSearch"
              :data="timesCircle"
              class="clear-icon"
              color="#dc3545"
              height="16"
              width="16"
              @click="clearSearch('subject')"
            />
          </div>
        </div>
      </div>

      <!-- Copy to Clipboard Button -->
      <button 
        class="btn btn-primary mr-3"
        @click="copyToClipboard"
        title="Copy current page data as CSV"
      >
        Copy to Clipboard
      </button>

      <div class="per-page-selector">
        <strong>Records per page:</strong>
        <select v-model="perPage" @change="handlePerPageChange" class="form-control d-inline-block w-auto ml-2">
          <option v-for="option in perPageOptions" :key="option" :value="option">
            {{ option }}
          </option>
        </select>
      </div>
      <!-- Add top pagination controls -->
      <div class="pagination-controls d-flex align-items-center">
        <div class="pagination-info mr-3">
          Showing {{ paginationInfo.from }} to {{ paginationInfo.to }} of {{ paginationInfo.total }} entries
        </div>
        <div class="pagination-buttons">
          <button 
            class="btn btn-secondary mr-2" 
            @click="handlePageChange(currentPage - 1)"
            :disabled="currentPage === 1"
          >
            Previous
          </button>
          <button 
            class="btn btn-secondary" 
            @click="handlePageChange(currentPage + 1)"
            :disabled="currentPage === paginationInfo.last_page"
          >
            Next
          </button>
        </div>
      </div>
    </div>

    <table>
      <thead>
        <tr>
          <th v-for="column in columns" 
              :key="column.field" 
              @click="handleSort(column.field)"
              :class="{ sortable: column.sortable }"
          >
            {{ column.label }}
            <span v-if="column.sortable" class="sort-icon">
              <icon v-if="sortField === column.field"
                   :data="sortDirection === 'asc' ? arrowUp : arrowDown"
                   color="#495057"
                   height="12"
                   width="12"
              />
              <icon v-else
                   :data="arrowsAltV"
                   color="#495057"
                   height="12"
                   width="12"
              />
            </span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="log in getActivityLogs.data" :key="log.id">
          <td>{{ log.id }}</td>
          <td>{{ log.log_name }}</td>
          <td>{{ log.description }}</td>
          <td>{{ log.subject_type }}</td>
          <td>
            <a 
              href="#" 
              @click.prevent="navigateToSubject(log)" 
              class="text-primary"
            >
              {{ log.subject_id }}
            </a>
          </td>
          <td>{{ getCauserName(log) }}</td>
          <td class="properties-cell" @click="showProperties(log.properties)">
            {{ truncateText(log.properties, 50) }}
            <span v-if="log.properties && log.properties.length > 50" class="view-more">...</span>
          </td>
          <td class="date-cell">{{ formatDate(log.created_at) }}</td>
        </tr>
      </tbody>
    </table>

    <div class="pagination-controls mt-3 d-flex justify-content-between align-items-center">
      <div class="pagination-info">
        Showing {{ paginationInfo.from }} to {{ paginationInfo.to }} of {{ paginationInfo.total }} entries
      </div>
      <div class="d-flex align-items-center">
        <!-- Add bottom Copy to Clipboard button -->
        <button 
          class="btn btn-primary mr-3"
          @click="copyToClipboard"
          title="Copy current page data as CSV"
        >
          Copy to Clipboard
        </button>
        <div class="pagination-buttons">
          <button 
            class="btn btn-secondary mr-2" 
            @click="handlePageChange(currentPage - 1)"
            :disabled="currentPage === 1"
          >
            Previous
          </button>
          <button 
            class="btn btn-secondary" 
            @click="handlePageChange(currentPage + 1)"
            :disabled="currentPage === paginationInfo.last_page"
          >
            Next
          </button>
        </div>
      </div>
    </div>

    <EditModal 
      ref="propertiesModal" 
      :show-footer="false"
      :edit="showModal"
      @close="showModal = false"
    >
      <template #formTitle>
        Properties Details
      </template>
      <template #form>
        <pre class="properties-content" v-html="formattedProperties"></pre>
      </template>
    </EditModal>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex';
import EditModal from '@/components/modal/EditModal';
import debounce from 'lodash/debounce';
import arrowUp from "@fa/solid/arrow-up.svg";
import arrowDown from "@fa/solid/arrow-down.svg";
import arrowsAltV from "@fa/solid/arrows-alt-v.svg";
import timesCircle from "@fa/solid/times-circle.svg";
import { ref } from 'vue'
import { useStore } from 'vuex';
import {useRouter} from "vue-router";

export default {
  components: {
    EditModal
  },
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      perPageOptions: [5, 10, 30, 50, 100, 250, 500],
      selectedProperties: null,
      showModal: false,
      sortField: 'created_at',
      sortDirection: 'desc',
      searchQuery: '',
      subjectIdSearch: '',  // Add this new data property
      columns: [
        { field: 'id', label: 'ID', sortable: true },
        { field: 'log_name', label: 'Log Name', sortable: true },
        { field: 'description', label: 'Description', sortable: true },
        { field: 'subject_type', label: 'Subject Type', sortable: true },
        { field: 'subject_id', label: 'Subject ID', sortable: true },
        { field: 'causer', label: 'Causer', sortable: false },
        { field: 'properties', label: 'Properties', sortable: false },
        { field: 'created_at', label: 'Created At', sortable: true },
      ],
      // Add the icons to data
      arrowUp,
      arrowDown,
      arrowsAltV,
      timesCircle,
    };
  },
  computed: {
    ...mapGetters(['getActivityLogs']),
    paginationInfo() {
      return {
        total: this.getActivityLogs.total || 0,
        from: this.getActivityLogs.from || 0,
        to: this.getActivityLogs.to || 0,
        last_page: this.getActivityLogs.last_page || 1
      };
    },
    formattedProperties() {
      if (!this.selectedProperties) return '';
      
      try {
        // If it's already a string, try to parse it as JSON
        const obj = typeof this.selectedProperties === 'string' 
          ? JSON.parse(this.selectedProperties) 
          : this.selectedProperties;

        // Convert the object to a formatted string with proper indentation
        return JSON.stringify(obj, null, 2)
            // Add HTML syntax highlighting
            .replace(/(".*?")/g, '<span class="json-string">$1</span>')
            .replace(/\b(true|false|null)\b/g, '<span class="json-keyword">$1</span>')
            .replace(/\b(\d+)\b/g, '<span class="json-number">$1</span>');
      } catch (e) {
        // If parsing fails, return the original string
        return this.selectedProperties;
      }
    }
  },
  created() {
    this.debouncedSearch = debounce(this.performSearch, 300);
    // Check for subject_id in route query
    if (this.$route.query.subject_id) {
      this.subjectIdSearch = this.$route.query.subject_id;
    }
    this.loadLogs();
  },
  methods: {
    ...mapMutations(["setNotification"]),
    ...mapActions(['fetchActivityLogs', 'filterActivityLogs', 'searchActivityLogs']),
    
    truncateText(text, length) {
      if (!text) return '';
      return text.length > length ? text.substring(0, length) : text;
    },

    showProperties(properties) {
      if (!properties) return;
      this.selectedProperties = properties;
      this.showModal = true;
      this.$refs.propertiesModal.show = true;
    },
    
    handleSort(field) {
      // If clicking the same field, toggle direction
      if (this.sortField === field) {
        this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
      } else {
        // New field, set to asc by default
        this.sortField = field;
        this.sortDirection = 'asc';
      }
      this.loadLogs();
    },
    
    async handlePerPageChange() {
      this.currentPage = 1; // Reset to first page when changing items per page
      await this.loadLogs();
    },
    
    async handlePageChange(page) {
      if (page < 1 || page > this.paginationInfo.last_page) return;
      this.currentPage = page;
      await this.loadLogs();
    },
    
    async loadLogs() {
      await this.fetchActivityLogs({
        per_page: this.perPage,
        page: this.currentPage,
        sort_field: this.sortField,
        sort_direction: this.sortDirection,
        search: this.searchQuery,
        subject_id_search: this.subjectIdSearch  // Add this new parameter
      });
    },
    
    formatDate(dateString) {
      if (!dateString) return '';
      const date = new Date(dateString);
      return new Intl.DateTimeFormat('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
      }).format(date);
    },
    
    handleSearch() {
      this.currentPage = 1; // Reset to first page when searching
      this.debouncedSearch();
    },

    async performSearch() {
      await this.loadLogs();
    },

    copyToClipboard() {
      if (!this.getActivityLogs.data || this.getActivityLogs.data.length === 0) {
        alert('No data to copy');
        return;
      }

      // Create headers row
      const headers = this.columns.map(col => col.label);

      // Create data rows
      const rows = this.getActivityLogs.data.map(log => {
        return this.columns.map(col => {
          let value = log[col.field];

          // Format dates
          if (col.field === 'created_at') {
            value = this.formatDate(value);
          }

          // Handle properties field
          if (col.field === 'properties') {
            value = typeof value === 'object' ? JSON.stringify(value) : value;
          }

          // Convert value to string and handle null/undefined
          value = value?.toString() || '';

          // Escape quotes and wrap values containing commas or quotes
          value = value.replace(/"/g, '""');
          if (value.includes(',') || value.includes('"') || value.includes('\n')) {
            value = `"${value}"`;
          }

          return value;
        });
      });

      // Combine all rows (including headers) and join with tab instead of comma
      const tsv = [headers, ...rows]
        .map(row => row.join('\t'))
        .join('\n');

      // Copy to clipboard
      navigator.clipboard.writeText(tsv).then(() => {
        // alert('Data copied to clipboard! You can now paste it into Excel or Google Sheets.');
        this.setNotification({
          show: true,
          type: "success",
          message: "Data copied to clipboard! You can now paste it into Excel or Google Sheets."
        });
      }).catch(err => {
        console.error('Failed to copy data:', err);
        // alert('Failed to copy data to clipboard');
        this.setNotification({
          show: true,
          duration: 5000,
          type: "error",
          message: 'Failed to copy data:',
          content: this.formulateErrorString(err)
        });
      });
    },

    clearSearch(type) {
      if (type === 'description') {
        this.searchQuery = '';
      } else if (type === 'subject') {
        this.subjectIdSearch = '';
      }
      this.handleSearch();
    },
    getCauserName(log) {
      if (!log.causer) return 'System';
      return `${log.causer.first_name} ${log.causer.last_name}`;
    },
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const uuidCache = ref(new Map())

    const fetchSubjectUuid = async (subjectId, type) => {
        const cacheKey = `${type}-${subjectId}`
        if (uuidCache.value.has(cacheKey)) {
            return uuidCache.value.get(cacheKey)
        }

        try {
            let uuid
            if (type === 'application') {
                uuid = await store.dispatch('fetchApplicationUuid', subjectId)
            } else if (type === 'tracedpension') {
                uuid = await store.dispatch('fetchTracedPensionUuid', subjectId)
            }

            if (uuid) {
                uuidCache.value.set(cacheKey, uuid)
            }
            return uuid
        } catch (error) {
            console.error('Error fetching UUID:', error)
            return null
        }
    }

    const navigateToSubject = async (row) => {
        const subjectId = row.subject_id
        const subjectType = row.subject_type.split('\\').pop().toLowerCase()

        try {
            if (subjectType === 'application') {
                const uuid = await store.dispatch('fetchApplicationUuid', subjectId)
                if (uuid) {
                    router.push(`/application/${uuid}`)
                }
            } else if (subjectType === 'tracedpension') {
                // For traced pensions, we need both the application UUID and traced pension UUID
                const { applicationUuid, tracedPensionUuid } = await store.dispatch('fetchTracedPensionUuids', subjectId)
                if (applicationUuid && tracedPensionUuid) {
                    router.push(`/application/${applicationUuid}/traced-pension/${tracedPensionUuid}`)
                }
            }
        } catch (error) {
            console.error('Error navigating to subject:', error)
        }
    }

    return {
        navigateToSubject
    }
  },
};
</script>

<style scoped>
/* Base styles */
.container {
  background-color: #f8f9fa;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

/* Table styles */
table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  margin: 1rem 0;
}

th, td {
  border: none;
  border-bottom: 1px solid #e9ecef;
  padding: 12px 16px;
  text-align: left;
  vertical-align: top;
}

th {
  background-color: #f8f9fa;
  font-weight: 600;
  color: #495057;
  //text-transform: uppercase;
  font-size: 0.85rem;
  letter-spacing: 0.5px;
  cursor: pointer;
  position: relative;
  padding-right: 30px !important; /* Make room for the icon */
}

tr:last-child td {
  border-bottom: none;
}

tr:hover {
  background-color: #f8f9fa;
}

/* Controls styling */
.d-flex.justify-content-between {
  background-color: white;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;
}

.search-container {
  flex: 1;
  max-width: 600px;  /* Increased to accommodate two search fields */
  margin-right: 1rem;
}

.search-field {
  flex: 1;
}

.gap-3 {
  gap: 1rem;
}

.search-container input {
  width: 100%;
  padding: 0.375rem 2.5rem 0.375rem 0.75rem; /* Added right padding for icon */
  font-size: 1rem;
  line-height: 1.5;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.search-container input:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
}

/* Button styles */
.btn {
  padding: 0.5rem 1rem;
  border-radius: 6px;
  font-weight: 500;
  transition: all 0.2s ease;
}

.btn-primary {
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:hover {
  background-color: #0056b3;
  border-color: #0056b3;
  transform: translateY(-1px);
}

.btn-secondary {
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:hover {
  background-color: #5a6268;
  border-color: #545b62;
  transform: translateY(-1px);
}

/* Select styling */
.per-page-selector select {
  border: 1px solid #e9ecef;
  border-radius: 6px;
  padding: 0.5rem;
  background-color: white;
  cursor: pointer;
  transition: all 0.2s ease;
}

.per-page-selector select:focus {
  border-color: #007bff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.15);
}

/* Properties cell styling */
.properties-cell {
  cursor: pointer;
  max-width: 300px;
  transition: all 0.2s ease;
}

.properties-cell:hover {
  background-color: #e9ecef;
}

/* Date cell styling */
.date-cell {
  white-space: nowrap;
  color: #6c757d;
  font-size: 0.9rem;
}

/* Sort icon styling */
.sort-icon {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  display: inline-flex;
  align-items: center;
}

/* Pagination info styling */
.pagination-info {
  color: #6c757d;
  font-size: 0.9rem;
}

/* Properties modal styling */
.properties-content {
  max-height: 500px;
  overflow-y: auto;
  white-space: pre-wrap;
  word-wrap: break-word;
  background-color: #1e1e1e;
  color: #d4d4d4;
  padding: 1.5rem;
  border-radius: 8px;
  font-family: 'Consolas', 'Monaco', 'Courier New', monospace;
  font-size: 14px;
  line-height: 1.6;
  text-align: left;
  margin: 0;
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2);
}

/* JSON syntax highlighting */
:deep(.json-string) { color: #ce9178; }
:deep(.json-number) { color: #b5cea8; }
:deep(.json-keyword) { color: #569cd6; }
:deep(.json-key) { color: #9cdcfe; }

/* Responsive adjustments */
@media (max-width: 768px) {
  .d-flex.justify-content-between {
    flex-direction: column;
    gap: 1rem;
  }

  .search-container {
    max-width: 100%;
  }

  .pagination-controls {
    flex-direction: column;
    align-items: stretch;
    gap: 0.5rem;
  }
}

.search-input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.clear-icon {
  position: absolute;
  right: 10px;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.clear-icon:hover {
  transform: scale(1.1);
}

/* Add to the existing <style> section */
td a {
  text-decoration: none;
}

td a:hover {
  text-decoration: underline;
}
</style>
