<template>
  <div class="container-fluid h-auto">
    <TitleHeader>Activity Log</TitleHeader>
    <div class="card">
      <div class="card-body -no-border">
        <ActivityLogTable />
      </div>
    </div>
  </div>
</template>
<script>
import ActivityLogTable from "@/components/ActivityLogTable.vue";

export default {
  name: "ActivityLog",
  components: {
    ActivityLogTable,
  },
  data: () => ({
  }),
  watch: {
  },
  computed: {
  },
  methods: {
  },
  async beforeMount() {

  }
};
</script>
<style type="text/css">

</style>
